import { useEffect, useRef, useState } from "react";
import { copyCanvas } from "../../utils/imageUtils";
import { ShootEvent } from "../ShootSession/ShootSession";
import { PsdTemplate, Template } from "./PsdTemplate";

const template_cache: Record<string, Template> = {}

export default (template_name: string) => {
  const templateRef = useRef<Template>();
  const [loaded, setLoaded] = useState(false);
  const [orientation, setOrientation] = useState<"landscape" | "portrait">();

  useEffect(() => {
    const load = async () => {
      if (template_cache[template_name]) {
        templateRef.current = template_cache[template_name]
      } else if (template_name.endsWith(".zip")) {
        templateRef.current = await PsdTemplate.initFromFirebase(template_name);
      } else {
        templateRef.current = await PsdTemplate.init(template_name);
      }
      template_cache[template_name] = templateRef.current
      
      const snapCount = templateRef.current.getSnapCount();
      if (snapCount === 0) {
        console.error(`No snap found in template ${template_name}`);
      } else {
        setLoaded(true);
      }
      const { width, height } = templateRef.current.getSize();
      if (width && height) {
        setOrientation(width > height ? "landscape" : "portrait");
      }
    };
    setLoaded(false);
    load();
  }, [template_name]);

  const processCanvasRef = useRef<HTMLCanvasElement>();
  const captureBufferRef = useRef<HTMLCanvasElement[]>([]);

  useEffect(() => {
    processCanvasRef.current = document.createElement("canvas");

    return processCanvasRef.current.remove();
  }, []);

  const process = async (
    event: ShootEvent,
    capture: HTMLCanvasElement | HTMLVideoElement
  ): Promise<HTMLCanvasElement | undefined> => {
    // const event = shootRef.current?.consumeEvent();
    const snapIndex = event?.snapIndex;
    const template = templateRef.current!;
    if (!event || !template || snapIndex === undefined) return;

    if (event.type === "reload") {
      // load frame resource
      await template.loadFrameResource(snapIndex);
    } else if (event.type === "exposure") {
      // save the exposure
      captureBufferRef.current[snapIndex] = document.createElement("canvas");
      copyCanvas(capture, captureBufferRef.current[snapIndex]);
    } else if (event.type === "final_process") {
      // draw final composite image
      await template.drawFinal(
        captureBufferRef.current,
        processCanvasRef.current!
      );
    } else if (event.type === "finished") {
      // clear buffer after finishing
      captureBufferRef.current.forEach((bf) => bf.remove());
      captureBufferRef.current = [];
    } else {
      if (event.type !== "final_intro" && event.type !== "final_preview") {
        const _capture = captureBufferRef.current[snapIndex] || capture;
        template.drawFrame(snapIndex, _capture, processCanvasRef.current!);
      }
    }
    return processCanvasRef.current;
  };
  return {
    process,
    snapCount: templateRef.current?.getSnapCount() || 0,
    loaded,
    orientation,
    useCutout: true
  };
};
