import { Layer, Psd } from "ag-psd";

const extractImage = async (
  layers: Layer[],
  left: number,
  top: number,
  width: number,
  height: number
) => {
  const canvasBuffer = document.createElement("canvas");
  const ctx = canvasBuffer.getContext("2d");

  canvasBuffer.width = width;
  canvasBuffer.height = height;
  ctx?.clearRect(0, 0, width, height);

  layers.forEach((layer) => {
    const rLeft = layer.left! - left!;
    const rTop = layer.top! - top!;

    if (layer.canvas) {
      ctx?.drawImage(layer.canvas, rLeft, rTop);
    }
  });

  const img = await createImageBitmap(canvasBuffer);
  canvasBuffer.remove();
  return img;
};

export interface SnapResource {
  mask: HTMLCanvasElement;
  bg: ImageBitmap;
  fg: ImageBitmap;
}

export const getSnapCount = (psd: Psd): number =>
  // psd.children?.filter((l: Layer) => l.name?.startsWith("snap")).length || 0;
  psd.children?.filter((l: Layer) => getLayerInfo(l)?.type === "snap").length ||
  0;

export type LayerInfo = {
  type: "snap" | "canvas";
  left: number;
  top: number;
  width: number;
  height: number;
};

export const getLayerInfo = (layer: Layer): LayerInfo | undefined => {
  const isSnapName = layer.name?.startsWith("snap");
  if (isSnapName && layer.canvas) {
    return {
      type: "snap",
      left: layer.left!,
      top: layer.top!,
      width: layer.right! - layer.left! + 1,
      height: layer.bottom! - layer.top! + 1
    };
  } else if (layer.canvas) {
    return {
      type: "canvas",
      left: layer.left!,
      top: layer.top!,
      width: layer.right! - layer.left! + 1,
      height: layer.bottom! - layer.top! + 1
    };
  }
};

export const getSnapResource = async (
  psd: Psd,
  snapIndex: number
): Promise<SnapResource | undefined> => {
  const layers = psd.children!;
  const snapLayers = layers
    .map((l, index) => ({ layer: l, layerIndex: index }))
    .filter((l) => getLayerInfo(l.layer)?.type === "snap")
    .reverse();

  if (snapLayers[snapIndex] !== undefined) {
    const { layer: snap, layerIndex } = snapLayers[snapIndex];
    const imgFilter = (l: Layer) => getLayerInfo(l)?.type !== "snap";

    const bg = await extractImage(
      layers.slice(0, layerIndex).filter(imgFilter),
      snap.left!,
      snap.top!,
      snap.canvas!.width!,
      snap.canvas!.height!
    );

    const fg = await extractImage(
      layers.slice(-layerIndex - 1).filter(imgFilter),
      snap.left!,
      snap.top!,
      snap.canvas!.width!,
      snap.canvas!.height!
    );
    return { mask: snap.canvas!, bg, fg };
  }
};
