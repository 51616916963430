export default (props: {
  elapsedPercent: number;
  duration: number;
  visible?: boolean;
}) => {
  const countdownValue = props.visible
    ? Math.ceil(((1 - props.elapsedPercent) * props.duration) / 1000) || ""
    : "";

  const countdownPercent = props.visible ? props.elapsedPercent : 1;

  return (
    <>
      <div
        style={{
          position: "absolute",
          right: "1%",
          fontSize: "5rem",
          color: "red",
          bottom: "max(1vh, 8px)",
          marginBottom: "1%",
          opacity: props.visible ? 1.0 : 0,
          transition: "opacity 0.5s"
        }}
      >
        {countdownValue}
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          height: "max(1vh, 8px)",
          opacity: props.visible ? 1.0 : 0,
          transition: "opacity 0.5s"
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            background: "red",
            width: `${countdownPercent * 100}%`
          }}
        ></div>
      </div>
    </>
  );
};
