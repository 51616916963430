import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BgRemovalType, CanvasVideoElem, Res } from "../../entities";
import { CloseIcon } from "../../icons";
import { AppContext, CamResName, CAM_RES } from "../../provider/AppProvider";
import { copyCanvas } from "../../utils/imageUtils";
import { getPixColor } from "../hooks/useCapture";

const Config: React.FC = () => {
  const nav = useNavigate();
  const {
    bgRemovalMode,
    setBgRemovealMode,

    chromaKey,
    setChromaKey,

    camResName,
    setCamResName,

    duration,
    setDuration,

    setRec,
    setFrameCallback,
    pauseBgRemoval,

    fullScreenHandle
  } = useContext(AppContext);
  const { similar, spill, smooth } = chromaKey; 

  const resDispRef = useRef<HTMLSpanElement>(null);
  const videoSourceRef = useRef<HTMLCanvasElement | HTMLVideoElement | null>(
    null
  );
  const screenCanvasRef = useRef<HTMLCanvasElement>(null);

  const processFrame = (cutout: CanvasVideoElem, source: CanvasVideoElem) => {
    copyCanvas(cutout, screenCanvasRef.current);
    videoSourceRef.current = source;
  };

  useEffect(() => {
    setFrameCallback(processFrame);
    setRec(true);
    pauseBgRemoval(false);
  }, []);

  return (
    <Container>
      <Paper style={{ margin: "16px", padding: "32px", position: "relative" }}>
        <div
          style={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
            opacity: 1.0,
            background:
              "linear-gradient(135deg, #444cf755 25%, transparent 25%) -27px 0/ 54px 54px, linear-gradient(225deg, #444cf7 25%, transparent 25%) -27px 0/ 54px 54px, linear-gradient(315deg, #444cf755 25%, transparent 25%) 0px 0/ 54px 54px, linear-gradient(45deg, #444cf7 25%, #e5e5f7 25%) 0px 0/ 54px 54px"
          }}
        >
          <canvas
            style={{ border: "solid 1px black" }}
            width={640}
            height={480}
            ref={screenCanvasRef}
            onClick={(e) => {
              const color = getPixColor(e, videoSourceRef.current!);
              setChromaKey({ ...chromaKey, color: [...color!] });
            }}
          />
        </div>
        <div style={{ height: "32px" }} />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Typography>Capture Size</Typography>
          </Grid>
          <Grid item xs={8}>
            <div style={{ alignItems: "center", display: "flex", gap: "16px" }}>
              <Select
                size="small"
                value={camResName}
                onChange={(e) => {
                  if (e.target.value !== camResName) {
                    setCamResName(e.target.value as CamResName);
                    setRec(false);
                    window.setTimeout(setRec, 250, true);
                  }
                }}
              >
                {Object.keys(CAM_RES).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
              <Typography>
                <span ref={resDispRef}></span>
              </Typography>
            </div>
          </Grid>

          <Grid item xs={4}>
            <Typography>Background Removal</Typography>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={bgRemovalMode}
              onChange={(e) => {
                setBgRemovealMode(e.target.value as BgRemovalType);
              }}
            >
              <FormControlLabel
                value="tensor"
                control={<Radio />}
                label="Auto"
              />
              <FormControlLabel
                value="chromakey"
                control={<Radio />}
                label="Chroma Key"
              />
              <FormControlLabel
                value="none"
                control={<Radio />}
                label="Turn off"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={4}>
            <Typography>Chroma Color</Typography>
          </Grid>
          <Grid item xs={8}>
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  border: "solid 1px",
                  borderRadius: "50%",
                  background: `rgb(${chromaKey.color.join(",")})`
                }}
              />
              <Typography variant="caption">
                Click on video to sample color
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography>Similar</Typography>
          </Grid>
          <Grid item xs={8}>
            <Slider
              min={0}
              max={0.2}
              step={0.001}
              value={similar}
              //scale={(value) => 1 ** value}
              onChange={(e, value) =>
                setChromaKey({ ...chromaKey, similar: value as number })
              }
              valueLabelDisplay="auto"
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Smooth</Typography>
          </Grid>
          <Grid item xs={8}>
            <Slider
              min={0}
              max={0.4}
              step={0.001}
              value={smooth}
              onChange={(e, value) =>
                setChromaKey({ ...chromaKey, smooth: value as number })
              }
              valueLabelDisplay="auto"
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Spill</Typography>
          </Grid>
          <Grid item xs={8}>
            <Slider
              min={0}
              max={0.2}
              step={0.001}
              value={spill}
              onChange={(e, value) =>
                setChromaKey({ ...chromaKey, spill: value as number })
              }
              valueLabelDisplay="auto"
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Full Screen</Typography>
          </Grid>
          <Grid item xs={8}>
            <Checkbox
              checked={fullScreenHandle.active}
              onChange={() => {
                if (fullScreenHandle.active) {
                  fullScreenHandle.exit();
                } else {
                  fullScreenHandle.enter();
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Duration (sec)</Typography>
          </Grid>
          <Grid item xs={4}>
            <Slider
              min={1}
              max={10}
              step={1}
              value={duration.pose / 1000}
              onChange={(e, value) =>
                setDuration({ ...duration, pose: (value as number) * 1000 })
              }
              valueLabelDisplay="auto"
            />
            <Typography variant="caption">Pose</Typography>
          </Grid>
          <Grid item xs={4}>
            <Slider
              min={1}
              max={5}
              step={1}
              value={duration.preview / 1000}
              onChange={(e, value) => {
                setDuration({
                  ...duration,
                  preview: (value as number) * 1000,
                  finalPreview: (value as number) * 1000
                });
              }}
              valueLabelDisplay="auto"
            />
            <Typography variant="caption">Preview</Typography>
          </Grid>
        </Grid>
        <div
          style={{ display: "flex", position: "absolute", top: 8, right: 8 }}
        >
          <IconButton onClick={() => nav(-1)}>
            <CloseIcon />
          </IconButton>
        </div>
      </Paper>
    </Container>
  );
};

export default Config;
