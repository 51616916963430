import { useEffect, useRef, useState } from "react";
import { ShootEvent } from "./ShootSession";

export default (
  event: ShootEvent | undefined,
  audioSources: Record<string, string>
) => {
  const audioRef = useRef<Record<string, HTMLAudioElement>>({});
  const bleepIntervalRef = useRef<number>(-1);
  const [loadedCount, setLoadedCount] = useState(0);

  useEffect(() => {
    // cleanup
    return () => {
      Object.values(audioRef.current).forEach((audioDom) => audioDom.remove());
      window.clearInterval(bleepIntervalRef.current);
    };
  }, []);

  useEffect(() => {
    const shallowEqual = () => {
      for (const key in audioSources) {
        if (
          audioRef.current[key]?.src.split("/").slice(-1)[0] !==
          audioSources[key].split("/").slice(-1)[0]
        )
          return false;
      }
      return true;
    };

    if (!shallowEqual()) {
      setLoadedCount(0);

      Object.keys(audioSources).forEach((name) => {
        audioRef.current[name] = document.createElement("audio");
        audioRef.current[name].src = audioSources[name];
        // onload never gets called
        setLoadedCount((k) => k + 1);
        // };
      });
    }
  }, [audioSources]);

  const playBleep = () => {
    if (audioRef.current["bleep"]) {
      audioRef.current["bleep"].currentTime = 0;
      audioRef.current["bleep"].play();
    }
  };

  useEffect(() => {
    if (event) {
      if (audioRef.current[event.type]) {
        audioRef.current[event.type].play();
      } else if (event.type === "pose") {
        if (bleepIntervalRef.current === -1) {
          playBleep();
          bleepIntervalRef.current = window.setInterval(playBleep, 1000);
        }
      } else {
        window.clearInterval(bleepIntervalRef.current);
        bleepIntervalRef.current = -1;
      }
    }
  }, [event]);

  return { loaded: loadedCount === Object.keys(audioSources).length };
};
