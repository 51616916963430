import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { saveAs } from "file-saver";
// import anime_sm from "./anime_md.psd";
import encanto from "./mario.psd";
// import travel from "./travel.psd";
// import disney from "./disney.psd";
// import anime from "./anime.psd";
// import shutter_sound from "./sound/camera-shutter-shorten.mp3";
import shutter_sound from "./sound/camera-shutter-and-flash-combined-short.mp3";
import bleep_sound from "./sound/correct-2.mp3";
import success_sound from "./sound/success-1.mp3";

const TEMPLATE = encanto;

import { readPsd } from "ag-psd";
import JSZip from "jszip";
import { AuthContext } from "../../provider/AuthProvider";
import { uploadFile } from "../../utils/db";

const PSD_ATTRS = [
  "bitsPerChannel",
  "channels",
  "children",
  "colorMode",
  "height",
  "width",
  // layer
  "top",
  "left",
  "bottom",
  "right",
  "blendMode",
  "clipping",
  "hidden",
  "layerColor",
  "name",
  "opacity",
  "transparencyProtected",
  // shape,
  "id",
  "mask",
  "vectorMask",
  "disabled",
  "paths",
  "knots",
  "open",
  "operation",
  "linked",
  "points"
];

const downloadCanvas = (
  name: string,
  canvas: HTMLCanvasElement,
  format = "image/jpg"
) => {
  const link = document.createElement("a");
  link.download = `${name}.webp`;
  link.href = canvas.toDataURL(format, 0.8);
  link.click();
  link.remove();
};

const resolveThumb = async (
  bound: { w: number; h: number },
  source: HTMLCanvasElement
): Promise<Blob> => {
  const oriW = source.width;
  const oriH = source.height;

  const scaleRatio = Math.min(bound.w, bound.h) / Math.max(oriW, oriH);
  const w = oriW * scaleRatio;
  const h = oriH * scaleRatio;

  const dest = document.createElement("canvas") as HTMLCanvasElement;

  dest.width = w;
  dest.height = h;
  const ctx = dest.getContext("2d");
  ctx!.imageSmoothingQuality = "high";
  ctx!.drawImage(source, 0, 0, w, h);
  const blob = await new Promise((res) => dest.toBlob(res, "image/webp", 0.8));

  dest.remove();

  return blob as Blob;
};

const ExportPsd = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!user) return;

    fetch(TEMPLATE)
      .then((res) => res.arrayBuffer())
      .then(async (arrayBuffer) => {
        const name = TEMPLATE.split("/").pop().split(".")[0];

        const psd1 = readPsd(arrayBuffer);
        const zip = new JSZip();

        // save the metadata
        const data = JSON.stringify(psd1, PSD_ATTRS);
        zip.file("data.json", data);

        // save the images as zip
        if (psd1.children) {
          for (let i = 0; i < psd1.children.length; i++) {
            const layer = psd1.children[i];
            if (layer.canvas) {
              const blob = await new Promise((res) =>
                layer.canvas?.toBlob(res, "image/webp", 0.7)
              );
              zip.file(`${encodeURIComponent(layer.name!)}.webp`, blob as Blob);
            }
          }

          const content = await zip.generateAsync({ type: "blob" });
          // saveAs(content, `${name}.zip`);
          // uploadFile(user, `uploadTemplates/${user.uid}`, content);
          // uploadFile(user, `ps42Templates`, {
          //   name,
          //   blob: content
          // });

          // save the thumbnail
          const thumbBlob = await resolveThumb(
            { w: 800, h: 800 },
            psd1.canvas!
          );

          // uploadFile(user, `uploadTemplates/${user.uid}`, {
          uploadFile(user, `ps42Templates`, {
            name,
            zipData: content,
            thumbData: thumbBlob
          });
        }

        // );
        // const dataURL = psd1.canvas?.toDataURL("image/webp", 0.8)
        // saveAs(blob as Blob, "test.webp");
      });
  }, [user]);

  return (
    <>
      <canvas ref={canvasRef} height={1000} width={1000} />
    </>
  );
};

export default ExportPsd;
