import { JSZipObject } from "jszip";
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import PhotoBooth from "../../components/PhotoBooth/PhotoBooth";
import { downloadExtract, downloadFile } from "../../utils/db";
import template from "./encanto.psd";
// import anime from "./anime.psd";
import { Psd } from "ag-psd";
import Camera from "../../components/Camera/useCamera";
// import { Res } from "../../entities";

const TestReadZipTemplate = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  function show_all_images(relativePath: string, file: JSZipObject) {
    console.log("...show all image", { relativePath, file });
    file.async("blob").then((blob) => {
      // console.log("...file blob", blob);
      // const ctx = canvasRef.current?.getContext("2d");
      // const img = new Image();
      // img.onload = function () {
      //   if (ctx) {
      //     if (canvasRef.current) {
      //       canvasRef.current.width = img.width;
      //       canvasRef.current.height = img.height;
      //     }
      //     ctx.drawImage(img, 0, 0, img.width, img.height);
      //     console.log("image size", img.width, img.height);
      //   }
      // };
      // img.src = URL.createObjectURL(blob);
    });
  }

  useEffect(() => {
    const loadImage = (path: string): Promise<HTMLImageElement> => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous"; // to avoid CORS if used with Canvas
        img.src = path;
        img.onload = () => {
          resolve(img);
        };
        img.onerror = (e) => {
          reject(e);
        };
      });
    };

    const readZip = async (path: string): Promise<Psd | undefined> => {
      // path
      const zipContentRaw = await downloadExtract(path);

      // build hash of files
      const zipContent: Record<string, JSZipObject> = {};
      let data: Psd | undefined = undefined;

      for (const key in zipContentRaw.files) {
        const fname = decodeURIComponent(key);
        if (fname === "data.json") {
          data = JSON.parse(
            await zipContentRaw.files["data.json"].async("string")
          );
        }
        zipContent[fname] = zipContentRaw.files[key];
      }

      if (data && data.children) {
        for (let k = 0; k < data.children?.length; k++) {
          const layer = data.children[k];

          const jzobj = zipContent[(layer?.name || "") + ".webp"];

          if (jzobj) {
            const imgBlob = await jzobj.async("blob");
            const img = await loadImage(URL.createObjectURL(imgBlob));

            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext("2d");
            if (ctx) {
              ctx.drawImage(img, 0, 0, img.width, img.height);
            }
            layer.canvas = canvas;
            img.remove();
          }
        }
      }

      return data;
    };
    readZip("/ps42Templates/anime_sm.zip");
  }, []);

  return (
    <div
      style={{
        display: "flex",
        inset: 0,
        position: "fixed",
        border: "solid 2px red"
      }}
    >
      <canvas ref={canvasRef} />
    </div>
  );
};

const TestPhotoBooth = () => {
  return <PhotoBooth template={template} />;
  // return <PhotoBooth template={"/ps42Templates/encanto.zip"} />;
};

const TestCamera = () => {
  // return <PhotoBooth template={anime_sm} />;
  // return <PhotoBooth template={"/ps42Templates/encanto.zip"} />;
  // const [res, setRes] = useState<Res | undefined>();

  return (
    <div>
      {/* <Camera
        resHint={{ width: 1000, height: 1000 }}
        record={true}
        visible={true}
      /> */}
    </div>
  );
};

const TestPrint = () => {
  const cref = useRef<HTMLCanvasElement>(null);

  return (
    <div>
      <canvas
        ref={cref}
        width={1000}
        height={800}
        style={{ border: "solid 30px blue" }}
      />
    </div>
  );
};

// export default TestReadZipTemplate;
// export default TestPrint;
export default TestPhotoBooth;
