import React, { useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PhotoBooth from "../../components/PhotoBooth/PhotoBooth";
import { saveAs } from "file-saver";
import { AppContext } from "../../provider/AppProvider";

// import mario from "../Test/encanto.psd"
import mario from "../Test/mario.psd"
const TEMPLATE_OVERRIDE = null

const PhotoBoothPage = () => {
  const { templateName } = useParams();
  const nav = useNavigate();
  const { fullScreenHandle } = useContext(AppContext);

  const print = () => {
    const css = `@page { size: ${orientation}; }`,
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");
    style.type = "text/css";
    style.media = "print";
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
    window.print();
    window.setTimeout(() => {
      style.remove();
      nav("/");
    }, 2000);
  };

  const handleFinish = async (
    canvas: HTMLCanvasElement,
    orientation: string
  ) => {
    // save to file.
    const blob = await new Promise((res) =>
      canvas.toBlob(res, "image/webp", 0.8)
    );

    saveAs(blob as Blob, `${templateName}.webp`);

    // print
    const css = `@page { size: ${orientation}; }`,
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");
    style.type = "text/css";
    style.media = "print";
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
    window.print();
    window.setTimeout(() => {
      style.remove();
      nav("/");
    }, 2000);
  };

  return (
    <div
      style={{
        background: "black",
        position: "fixed",
        inset: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden"
      }}
      onDoubleClick={() => {
        if (fullScreenHandle.active) {
          fullScreenHandle.exit();
        } else {
          fullScreenHandle.enter();
        }
      }}
    >
      <PhotoBooth
        template={TEMPLATE_OVERRIDE || `/ps42Templates/${templateName}.zip`}
        onFinish={handleFinish}
      />
    </div>
  );
};

export default PhotoBoothPage;
