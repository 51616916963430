import { Psd, readPsd } from "ag-psd";
import JSZip, { JSZipObject } from "jszip";
import { downloadExtract } from "../../utils/db";
import { maskPhoto } from "../../utils/imageUtils";
import {
  getLayerInfo,
  getSnapCount,
  getSnapResource,
  SnapResource
} from "../../utils/psdUtils";

const maskBuffer = document.createElement("canvas");
import logo from "../../pages/Test/logo.png";

const asyncLoadImage = (path: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // to avoid CORS if used with Canvas
    img.src = path;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (e) => {
      reject(e);
    };
  });
};

const readZipPsd = async (zipContentRaw: JSZip): Promise<Psd | undefined> => {
  // path

  // build hash of files
  const zipContent: Record<string, JSZipObject> = {};
  let data: Psd | undefined = undefined;

  for (const key in zipContentRaw.files) {
    const fname = decodeURIComponent(key);
    if (fname === "data.json") {
      data = JSON.parse(await zipContentRaw.files["data.json"].async("string"));
    }
    zipContent[fname] = zipContentRaw.files[key];
  }

  if (data && data.children) {
    for (let k = 0; k < data.children?.length; k++) {
      const layer = data.children[k];

      const jzobj = zipContent[(layer?.name || "") + ".webp"];

      if (jzobj) {
        const imgBlob = await jzobj.async("blob");
        const img = await asyncLoadImage(URL.createObjectURL(imgBlob));

        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.drawImage(img, 0, 0, img.width, img.height);
        }
        layer.canvas = canvas;
        img.remove();
      }
    }
  }

  return data;
};

export interface Template {
  loadFrameResource: (index: number) => void;
  drawFrame: (
    index: number,
    source: HTMLCanvasElement,
    dest: HTMLCanvasElement
  ) => void;
  drawFinal: (sources: HTMLCanvasElement[], dest: HTMLCanvasElement) => void;
  getSnapCount: () => number;
  getSize: () => { width: number; height: number };
}

const imgLogo = new Image();
imgLogo.src = logo;
export class PsdTemplate implements Template {
  psd: Psd;
  cachedSnapResource: { index: number; resource: SnapResource } | undefined;

  private constructor(psd: Psd) {
    this.psd = psd;
  }

  static async init(psdPath: any) {
    const res = await fetch(psdPath);
    const buf = await res.arrayBuffer();
    const psd = readPsd(buf, {});
    return new PsdTemplate(psd);
  }

  static async initFromFirebase(psdPath: string) {
    const zipContentRaw = await downloadExtract(psdPath);
    const psd = await readZipPsd(zipContentRaw);
    if (psd === undefined) throw new Error(`Fail to read from ${psdPath}`);
    return new PsdTemplate(psd);
  }

  getSnapCount = () => {
    return getSnapCount(this.psd);
  };

  getSize = () => {
    return { width: this.psd.width, height: this.psd.height };
  };

  loadFrameResource = async (index: number) => {
    if (this.cachedSnapResource?.index !== index) {
      const resource = await getSnapResource(this.psd, index);
      if (resource) {
        this.cachedSnapResource = { index, resource };
      }
    }
  };

  drawFrame = async (
    index: number,
    source: HTMLCanvasElement | HTMLVideoElement,
    dest: HTMLCanvasElement
  ) => {
    if (this.cachedSnapResource?.index !== index) {
      await this.loadFrameResource(index);
    }

    if (this.cachedSnapResource?.index === index) {
      const { resource } = this.cachedSnapResource;

      if (resource) {
        const { height, width } = resource.mask;

        dest.width = width;
        dest.height = height;

        maskBuffer.width = width;
        maskBuffer.height = height;

        maskPhoto(source, resource.mask, maskBuffer);

        const ctx = dest.getContext("2d");

        ctx?.clearRect(0, 0, dest.width, dest.height);
        ctx?.drawImage(resource.bg, 0, 0, width, height);
        ctx?.drawImage(maskBuffer, 0, 0, width, height);
        ctx?.drawImage(resource.fg, 0, 0, width, height);
      }
    }
  };

  drawFinal = async (sources: HTMLCanvasElement[], dest: HTMLCanvasElement) => {
    const i = 0;
    dest.width = this.psd.width;
    dest.height = this.psd.height;

    const ctx2 = sources[0].getContext("2d");
    ctx2?.clearRect(0, 0, 100, 100);
    ctx2?.fillText("0", 0, 0, 10);

    const temp = document.createElement("canvas");

    const ctx = dest.getContext("2d");
    let snapIndex = this.getSnapCount() - 1;

    if (this.psd?.children) {
      for (let i = 0; i < this.psd?.children?.length; i++) {
        const layer = this.psd.children[i];
        const layerInfo = getLayerInfo(layer);

        if (layerInfo?.type == "snap") {
          await this.drawFrame(snapIndex, sources[snapIndex], temp);

          ctx?.drawImage(
            // sources[snapIndex],
            temp,
            layerInfo.left,
            layerInfo.top,
            layerInfo.width,
            layerInfo.height
          );
          snapIndex -= 1;
        } else if (layerInfo?.type == "canvas") {
          ctx?.drawImage(layer.canvas!, layerInfo.left, layerInfo.top);
        }
      }
    }

    ctx?.drawImage(imgLogo, 0, 0, 800, 280);

    temp.remove();
  };
}
