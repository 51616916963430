import { useEffect, useRef } from "react";
import { Camera } from "@mediapipe/camera_utils";
import {
  Results,
  ResultsListener,
  SelfieSegmentation
} from "@mediapipe/selfie_segmentation";

function App() {
  const inputVideoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const selfieSegRef = useRef<SelfieSegmentation | null>(null);

  useEffect(() => {
    selfieSegRef.current = new SelfieSegmentation({
      locateFile: (file) => {
        console.log(
          "....",
          `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`
        );
        return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
      }
    });

    if (!canvasRef.current) return;
    if (!inputVideoRef.current) return;

    selfieSegRef.current.setOptions({
      modelSelection: 1,
      selfieMode: true
    });

    selfieSegRef.current.onResults(onResults);

    const camera = new Camera(inputVideoRef.current, {
      onFrame: async () => {
        if (inputVideoRef.current && selfieSegRef.current) {
          await selfieSegRef.current.send({ image: inputVideoRef.current });
          console.log(
            "...inputVideoRef.current",
            inputVideoRef.current.videoHeight,
            inputVideoRef.current.videoWidth
          );
        }
      },
      width: 640,
      height: 480
    });
    camera.start();
  }, []);

  const onResults: ResultsListener = (results: Results) => {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext("2d");
    if (!ctx) return;

    ctx.save();
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctx.drawImage(
      results.segmentationMask,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    ctx.globalCompositeOperation = "source-in";
    ctx.drawImage(
      results.image,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    ctx.restore();
  };

  return (
    <div className="App">
      <video
        autoPlay
        ref={inputVideoRef}
        width={640}
        height={480}
        style={{ display: "none" }}
      />
      <canvas ref={canvasRef} width={640} height={480} />
    </div>
  );
}

export default App;
