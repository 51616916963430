import React, { useEffect, useRef } from "react";

import {
  Results,
  ResultsListener,
  SelfieSegmentation
} from "@mediapipe/selfie_segmentation";

export default (disabled?: boolean) => {
  const selfieSegRef = useRef<SelfieSegmentation | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    canvasRef.current = document.createElement("canvas");
    return () => canvasRef.current?.remove();
  }, []);

  useEffect(() => {
    if (disabled) {
      if (canvasRef.current) {
        canvasRef.current.height = 1;
        canvasRef.current.height = 1;
      }
    } else {
      selfieSegRef.current = new SelfieSegmentation({
        locateFile: (file: string) => {
          const location = `/mediaPipeSelfieSeg/${file}`;
          return location;
        }
      });
      selfieSegRef.current.setOptions({
        modelSelection: 1
      });
      selfieSegRef.current.onResults(onResults);
    }
  }, [disabled]);

  const onResults: ResultsListener = (results: Results) => {
    if (!canvasRef.current) return;

    canvasRef.current.height = results.image.height;
    canvasRef.current.width = results.image.width;

    const ctx = canvasRef.current.getContext("2d");
    if (!ctx) return;

    ctx.save();
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctx.drawImage(
      results.segmentationMask,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    ctx.globalCompositeOperation = "source-in";
    ctx.drawImage(
      results.image,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    ctx.restore();
  };
  return { selfieSegRef, canvasRef };
};
