import React, { useEffect, useRef } from "react";
import { BgRemovalType, CanvasVideoElem, Res } from "../../entities";
import { useChromaKeyProcessor, ChromaKeySpec } from "../hooks/useChromaKey";
import { Camera as CameraBase } from "@mediapipe/camera_utils";
import useMediaPipe from "../hooks/useMediaPipe";
interface CameraProps {
  record?: boolean;
  visible?: boolean;

  resHint?: Res;
  direction?: "front" | "back";

  bgRemovalMode?: BgRemovalType;
  chromaKey?: ChromaKeySpec;

  processFrameRef?: React.MutableRefObject<
    ((source: CanvasVideoElem, ori: CanvasVideoElem) => void) | undefined
  >;

  processBgRemoval: boolean;

  realResUpdateCallback?: (res: Res) => void;

  displayFPS?: boolean;
}

// this is use for initalization purpose.
// this is dangerous to be used within a component as creates an expensive
// canvas dom, and if not manage property, re-renders can quickly exhause memory.
// Hence have it outside makes more sense.
const useCamera = ({
  record = false,
  resHint,
  displayFPS = true,
  ...props
}: CameraProps) => {
  const vRef = useRef<HTMLVideoElement | null>(null); // the capture footage.
  const camRef = useRef<CameraBase | null>(null);
  const mStreamRef = useRef<MediaStream | null>(null);
  const removalModeRef = useRef(props.bgRemovalMode);
  const processBgRemovalRef = useRef(props.processBgRemoval);

  useEffect(() => {
    removalModeRef.current = props.bgRemovalMode;
  }, [props.bgRemovalMode]);

  useEffect(() => {
    processBgRemovalRef.current = props.processBgRemoval;
  }, [props.processBgRemoval]);

  // process Frame
  const onFrame = async () => {
    if (!vRef.current) return;

    if (!mStreamRef.current && vRef.current && vRef.current.srcObject) {
      mStreamRef.current = vRef.current.srcObject as MediaStream;
    }

    if (props.realResUpdateCallback) {
      props.realResUpdateCallback({
        width: vRef.current.videoWidth,
        height: vRef.current.videoHeight
      });
    }
    // source image
    const source = vRef.current;

    const res = {
      width: vRef.current.videoWidth,
      height: vRef.current.videoHeight
    };

    let processed: HTMLVideoElement | HTMLCanvasElement = source;

    if (processBgRemovalRef.current) {
      if (removalModeRef.current === "chromakey") {
        const result = chromaKeyProcessorRef.current(res, source);
        if (result) {
          processed = result;
        }
      } else if (removalModeRef.current === "tensor") {
        if (mediaPip.selfieSegRef.current) {
          await mediaPip.selfieSegRef.current.send({ image: source });
          const result = mediaPip.canvasRef.current;
          if (result) {
            processed = result;
          }
        }
      }
    }

    if (props.processFrameRef && props.processFrameRef.current) {
      props.processFrameRef.current(processed, source);
    }
  };

  // chroma
  const chromaKeyProcessorRef = useChromaKeyProcessor(
    props.chromaKey,
    props.bgRemovalMode !== "chromakey" && processBgRemovalRef.current
  );

  const mediaPip = useMediaPipe(
    props.bgRemovalMode !== "tensor" && processBgRemovalRef.current
  );

  const terminateStream = () => {
    if (camRef.current) {
      camRef.current.stop();
      camRef.current = null;
    }
    if (mStreamRef.current) {
      mStreamRef.current.getTracks().forEach((track) => track.stop());
      mStreamRef.current = null;
    }
  };

  useEffect(() => {
    if (!vRef.current) {
      vRef.current = document.createElement("video");
    }
    reset();

    return terminateStream;
  }, []);

  const reset = () => {
    terminateStream();

    if (record && vRef.current) {
      camRef.current = new CameraBase(vRef.current, { onFrame, ...resHint });
      camRef.current.start();
    }
  };

  useEffect(reset, [record]);

  // return (
  //   <video
  //     autoPlay={true}
  //     muted={true}
  //     ref={vRef}
  //     style={{ display: "none" }}
  //   />
  // );
};

export default useCamera;
