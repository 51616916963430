//Dvr, ImportantDevices, OndemandVideo
export const AppName = "Menu 4K";

import _SettingsIcon from "@mui/icons-material/Settings";
import _AccountCircleIcon from "@mui/icons-material/AccountCircle";
import _NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// import _DisplayIcon from "@mui/icons-material/TvOutlined";
// import _FilesIcon from "@mui/icons-material/PhotoLibrary";
// import _PlaylistIcon from "@mui/icons-material/PlaylistPlay";

// import _AddIcon from "@mui/icons-material/Add";
// import _AddDisplayIcon from "@mui/icons-material/AddToQueue";
// import _AddFilesIcon from "@mui/icons-material/DriveFolderUploadOutlined";
// import _AddPlaylistIcon from "@mui/icons-material/PlaylistAdd";

// import _DeleteIcon from "@mui/icons-material/Delete";
import _CloseIcon from "@mui/icons-material/Close";
// import _DeletedFileIcon from "@mui/icons-material/Warning";

// import _ImageIcon from "@mui/icons-material/ImageOutlined";
// import _MovieIcon from "@mui/icons-material/SmartDisplay";

// import _PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
// import _TimerIcon from "@mui/icons-material/Timer";

// import _NavBackIcon from "@mui/icons-material/ArrowBack";

export const SettingsIcon = (props: any) => <_SettingsIcon {...props} />;
export const AccountCircleIcon = (props: any) => (
  <_AccountCircleIcon {...props} />
);
export const NavigateBeforeIcon = (props: any) => (
  <_NavigateBeforeIcon {...props} />
);

// export const AddDisplayIcon = (props: any) => <_AddDisplayIcon {...props} />;
// export const AddFilesIcon = (props: any) => <_AddFilesIcon {...props} />;
// export const AddPlaylistIcon = (props: any) => <_AddPlaylistIcon {...props} />;

// export const AddIcon = (props: any) => <_AddIcon {...props} />;
// export const DeleteIcon = (props: any) => <_DeleteIcon {...props} />;
// export const DeletedFileIcon = (props: any) => <_DeletedFileIcon {...props} />;

// export const DisplayIcon = (props: any) => <_DisplayIcon {...props} />;
// export const FilesIcon = (props: any) => <_FilesIcon {...props} />;
// export const PlaylistIcon = (props: any) => <_PlaylistIcon {...props} />;

// export const PlaylistAddIcon = (props: any) => <_PlaylistAddIcon {...props} />;

export const CloseIcon = (props: any) => <_CloseIcon {...props} />;
// export const NavBackIcon = (props: any) => <_NavBackIcon {...props} />;

// export const MovieIcon = (props: any) => <_MovieIcon {...props} />;
// export const ImageIcon = (props: any) => <_ImageIcon {...props} />;
// export const TimerIcon = (props: any) => <_TimerIcon {...props} />;
