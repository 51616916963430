import { useEffect, useState } from "react";

export default (enable: boolean) => {
  const [startTime, setStartTime] = useState<number>(-1);
  const [frameCount, setFrameCount] = useState(0);
  useEffect(() => {
    setStartTime(new Date().getTime());
  }, []);

  return {
    fps: frameCount / ((new Date().getTime() - startTime) / 1000),
    increment: () => setFrameCount((fc) => fc + 1)
  };
};
