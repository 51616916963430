import React, { useEffect, useRef, useState } from "react";
import {
  FullScreen,
  FullScreenHandle,
  useFullScreenHandle
} from "react-full-screen";
import useCamera from "../components/Camera/useCamera";
import { ChromaKeySpec } from "../components/hooks/useChromaKey";
import { BgRemovalType, Res } from "../entities";
import { useCookies } from "react-cookie";

export type CamResName = "low" | "medium" | "high";
export type CamRes = Record<CamResName, Res>;
export const CAM_RES: CamRes = {
  low: { width: 320, height: 240 },
  medium: { width: 640, height: 480 },
  high: { width: 1280, height: 720 }
};

export type DurationName = "pose" | "preview" | "finalPreview";

type AppContextProps = {
  chromaKey: ChromaKeySpec;
  setChromaKey: (s: ChromaKeySpec) => void;

  camRes: Res;
  camResName: CamResName;
  setCamResName: (n: CamResName) => void;
  setCamRes: React.Dispatch<React.SetStateAction<Res>>;

  rec: boolean;
  setRec: (rec: boolean) => void;

  pauseBgRemoval: (pause: boolean) => void;

  fullScreenHandle: FullScreenHandle;
  duration: Record<DurationName, number>;
  setDuration: (d: Record<DurationName, number>) => void;

  bgRemovalMode: BgRemovalType;
  setBgRemovealMode: (r: BgRemovalType) => void;

  setFrameCallback: (
    ref: (
      source: HTMLCanvasElement | HTMLVideoElement,
      ori: HTMLCanvasElement | HTMLVideoElement
    ) => void
  ) => void;
};

export const AppContext = React.createContext<AppContextProps>({
  chromaKey: {} as ChromaKeySpec,
  setChromaKey: () => undefined,

  camRes: {} as Res,
  camResName: "medium",
  setCamResName: () => undefined,
  setCamRes: () => undefined,

  rec: true,
  setRec: () => undefined,

  pauseBgRemoval: () => undefined,

  fullScreenHandle: null as unknown as FullScreenHandle,
  duration: {} as Record<DurationName, number>,
  setDuration: () => undefined,
  bgRemovalMode: "tensor",
  setBgRemovealMode: () => undefined,
  setFrameCallback: () => undefined
});

export const AppProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [camRes, setCamRes] = useState<Res>({ width: 640, height: 480 });
  const [rec, setRec] = useState(false);
  const [processBgRemoval, setProcessBgRemoval] = useState<boolean>(true);

  const [camResName, setCamResName] = useState<CamResName>("medium");
  const [bgRemovalMode, setBgRemovealMode] = useState<BgRemovalType>("tensor");

  const frameCallbackRef =
    useRef<
      (
        source: HTMLCanvasElement | HTMLVideoElement,
        ori: HTMLCanvasElement | HTMLVideoElement
      ) => void
    >();

  const setFrameCallback = (
    ref: (
      source: HTMLCanvasElement | HTMLVideoElement,
      ori: HTMLCanvasElement | HTMLVideoElement
    ) => void
  ) => {
    frameCallbackRef.current = ref;
  };

  const [duration, setDuration] = useState({
    pose: 5000,
    preview: 2000,
    finalPreview: 2000
  });

  const fullScreenHandle = useFullScreenHandle();

  const [chromaKeyCookie, setChromaKeyCookie] = useCookies(['chromaKey']);

  useEffect(() => {
    let data;
    if (chromaKeyCookie["chromaKey"]) {
      data = chromaKeyCookie["chromaKey"]
    }

    if (data) {
      console.log('....data', data)
      _setChromaKey(data)
    }
  }, [])

  const [chromaKey, _setChromaKey] = useState<ChromaKeySpec>({
    color: [255, 255, 255],
    similar: 0,
    spill: 0.05,
    smooth: 0.05
  });

  const setChromaKey = (chromaKey: ChromaKeySpec) => {
    _setChromaKey(chromaKey)
    setChromaKeyCookie("chromaKey", JSON.stringify(chromaKey))
  }

  useCamera({
    record: rec,
    processBgRemoval,
    chromaKey,
    resHint: CAM_RES[camResName],
    bgRemovalMode,
    processFrameRef: frameCallbackRef
  });

  return (
    <AppContext.Provider
      value={{
        camRes,
        setCamRes,

        camResName,
        setCamResName,

        rec,
        setRec,

        bgRemovalMode,
        setBgRemovealMode,
        pauseBgRemoval: (pause) => {
          setProcessBgRemoval(!pause);
        },

        chromaKey,
        setChromaKey,

        fullScreenHandle,
        duration,
        setDuration,

        setFrameCallback
      }}
    >
      <FullScreen handle={fullScreenHandle}>
        <div
          style={{
            background: "skyblue",
            position: "fixed",
            inset: "0px",
            overflow: "auto"
          }}
        >
          {props.children}
        </div>
      </FullScreen>
    </AppContext.Provider>
  );
};
