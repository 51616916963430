// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCu7e8Drg8A7CZYeeKUIPTO-KWRtOYDHNw",
  authDomain: "photocado-321.firebaseapp.com",
  projectId: "photocado-321",
  storageBucket: "photocado-321.appspot.com",
  messagingSenderId: "530557013329",
  appId: "1:530557013329:web:9d02a4bc1070df79511770",
  measurementId: "G-1R41BE0LKG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

// why recommend to do this, getAuth doesn't see to do anything.
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
