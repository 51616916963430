import { useContext, useEffect, useRef, useState } from "react";
import useFPS from "../hooks/useFPS";
import { ShootEvent, ShootSession } from "../ShootSession/ShootSession";
import useShootSoundEffects from "../ShootSession/useShootSoundEffects";
import useTemplateRenderer from "../Template/useTemplateRenderer";

import shutter_sound from "./sounds/camera-shutter-and-flash-combined-short.mp3";
import bleep_sound from "./sounds/correct-2.mp3";
import success_sound from "./sounds/success-1.mp3";

import useAnimateShutter from "../ShootSession/useAnimateShutter";

import CountdownDisplay from "../CountdownDisplay/CountdownDisplay";
import { AppContext, CAM_RES } from "../../provider/AppProvider";
import { copyCanvas } from "../../utils/imageUtils";

export default (props: {
  template: string;
  onFinish?: (c: HTMLCanvasElement, orientation: string) => void;
}) => {
  const displayCanvasRef = useRef<HTMLCanvasElement>(null);
  const shootRef = useRef<ShootSession>();

  const {
    chromaKey,
    camResName,
    duration,
    bgRemovalMode,
    pauseBgRemoval,
    setRec,
    setFrameCallback
  } = useContext(AppContext);
  const { fps, increment: upFrameCount } = useFPS(true);

  const [shootEvent, setShootEvent] = useState<ShootEvent>();

  const processFrame = async (
    capture: HTMLCanvasElement | HTMLVideoElement
  ) => {
    const event = shootRef.current?.consumeEvent();
    if (event) {
      const result = await process(event, capture);
      if (result && displayCanvasRef.current) {
        setShootEvent(() => ({ ...event }));
        copyCanvas(result, displayCanvasRef.current);
        upFrameCount();
      }
    }
  };

  useEffect(() => {
    setFrameCallback(processFrame);
    setRec(true);
    pauseBgRemoval(false);
  }, []);

  const {
    loaded: templateReady,
    snapCount,
    process,
    useCutout,
    orientation
  } = useTemplateRenderer(props.template);

  const { loaded: audioReady } = useShootSoundEffects(shootEvent, {
    shutter_open: shutter_sound,
    final_intro: success_sound,
    bleep: bleep_sound
  });

  const handleShootComplete = async () => {
    if (props.onFinish) {
      console.debug(`Shoot finish @ FPS: ${fps}.`);
      props.onFinish(displayCanvasRef.current!, orientation as string);
    }
  };
  useAnimateShutter(shootEvent, displayCanvasRef.current);

  useEffect(() => {
    if (audioReady && templateReady) {
      ShootSession.poseLength = duration?.pose || ShootSession.poseLength;

      ShootSession.previewLength =
        duration?.preview || ShootSession.finalIntroLength;

      ShootSession.finalPreviewLength =
        duration?.finalPreview || ShootSession.finalPreviewLength;

      shootRef.current = new ShootSession(snapCount, handleShootComplete);
    }

    shootRef.current?.restart();
  }, [audioReady, templateReady]);

  // useCamera()
  return (
    <>
      {/* <Camera
        resHint={CAM_RES[camResName]}
        record={true}
        bgRemovalMode={bgRemovalMode}
        chromaKey={useCutout ? chromaKey : undefined}
        onProcessFrame={async (
          capture: HTMLCanvasElement | HTMLVideoElement
        ) => {
          const event = shootRef.current?.consumeEvent();
          if (event) {
            const result = await process(event, capture);
            if (result && displayCanvasRef.current) {
              setShootEvent(() => ({ ...event }));
              copyCanvas(result, displayCanvasRef.current);
              upFrameCount();
            }
          }
        }}
      /> */}
      <canvas
        ref={displayCanvasRef}
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
      />
      <CountdownDisplay
        elapsedPercent={shootEvent?.elapsed || 0}
        duration={ShootSession.poseLength || 0}
        visible={shootEvent?.type === "pose"}
      />
    </>
  );
};
