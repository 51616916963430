import { Grid, Typography } from "@mui/material";

import { useContext, useEffect, useRef, useState } from "react";
import { AppContext, CAM_RES } from "../../provider/AppProvider";

import { useNavigate } from "react-router-dom";
import { copyCanvas } from "../../utils/imageUtils";
import { CanvasVideoElem } from "../../entities";

import logo from "../Test/logo.png";

const useSetBackground = (color: string) => {
  useEffect(() => {
    document.body.style.background = color;
  }, []);
};

const Dashboard = () => {
  const {
    chromaKey,
    camResName,
    bgRemovalMode,
    setRec,
    setFrameCallback,
    pauseBgRemoval
  } = useContext(AppContext);

  const screenCanvasRef = useRef<HTMLCanvasElement>(null);
  // const [rec, setRec] = useState(false);

  useSetBackground("#fee");

  const nav = useNavigate();

  useEffect(() => {
    setRec(true);
    pauseBgRemoval(true);
    setFrameCallback((cutout: CanvasVideoElem) => {
      copyCanvas(cutout, screenCanvasRef.current);
    });
    // return () => setRec(false);
  }, []);

  return (
    <div style={{ padding: "8px" }}>
      <div style={{ position: "fixed", bottom: "32px", right: "32px" }}>
        <canvas
          ref={screenCanvasRef}
          style={{ width: "240px", objectFit: "contain" }}
        />
      </div>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img src={logo} style={{ width: "300px" }} />
        </Grid>
        {/* <Grid item xs={6} style={{ display: "flex", justifyContent: "right" }}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/photocado-321.appspot.com/o/ps42Templates%2Fthumbs%2Fanime_md.zip.webp?alt=media&token=18c02b2f-9098-4fb3-8643-dd6776aee7ad"
            style={{ width: "50%" }}
            onClick={() => nav("/ps42/2024/anime_md")}
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          {/* <img
            src="https://firebasestorage.googleapis.com/v0/b/photocado-321.appspot.com/o/ps42Templates%2Fthumbs%2Fdisney.zip.webp?alt=media&token=6fe97fbd-3013-4817-9dee-0d4f3672e37f"
            style={{ width: "50%" }}
            onClick={() => nav("/ps42/2024/disney")}
          /> */}

          <img
            src="https://firebasestorage.googleapis.com/v0/b/photocado-321.appspot.com/o/ps42Templates%2Fthumbs%2Ftravel.zip.webp?alt=media&token=46729e6d-7ac8-4d29-86d4-dbdce100e882"
            style={{ width: "60%", objectFit: "contain", cursor: "pointer" }}
            onClick={() => nav("/ps42/2024/travel")}
          />
          <div>
            <Typography>Click to start</Typography>
          </div>
        </Grid>
        {/* <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "flex-start"
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/photocado-321.appspot.com/o/ps42Templates%2Fthumbs%2Fmario.zip.webp?alt=media&token=4cca8def-7b13-451a-afac-a4dc98271141"
            style={{ width: "40%", objectFit: "contain" }}
            onClick={() => nav("/ps42/2024/mario")}
          />
        </Grid>
        <Grid item xs={6}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/photocado-321.appspot.com/o/ps42Templates%2Fthumbs%2Fencanto.zip.webp?alt=media&token=6a896df6-d7cc-4fc9-83b8-7da4b850b2a5"
            style={{ width: "40%" }}
            onClick={() => nav("/ps42/2024/encanto")}
          />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Dashboard;
