import { User } from "firebase/auth";
import { db } from "../firebaseSetup";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  UploadResult
} from "firebase/storage";
import JSZip from "jszip";

export interface TemplateSource {
  name: string;
  zipData: Blob;
  thumbData: Blob;
  hashId?: string;
}

// export const recordUploadsToDB = async (
//   user: User,
//   uploadSources: TemplateSource[],
//   results: UploadResult[]
// ) => {
//   const updates: Record<string, any> = {};
//   results.forEach((upload, index) => {
//     const meta = upload.metadata;
//     const source = uploadSources[index];
//     updates[source.hashId] = {
//       fullPath: meta.fullPath,

//       size: source.file.size,
//       ownerId: user.uid,
//       uploadDate: meta.timeCreated,

//       width: source.width,
//       height: source.height,
//       duration: source.duration,
//       type: source.type,

//       originalModified: source.file.lastModified,
//       originalName: source.file.name,
//       hashId: source.hashId
//     };
//   });

//   updateDoc(getUserUploadsDoc(user), updates);
// };

export const uploadFile = (
  owner: User,
  uploadRoot: string,
  // file: Blob
  uploadContent: TemplateSource
): Promise<UploadResult> => {
  const { zipData, name, thumbData } = uploadContent;
  const storage = getStorage();
  const hashId = uploadContent.hashId ? `${uploadContent.hashId}.` : "";
  const filename = `${hashId}${name}.zip`;
  // const filename = `${hashId}.zip`;
  const storageRef = ref(storage, `${uploadRoot}/${filename}`);

  console.log("...upload content", { owner, uploadRoot, uploadContent });

  // no thumbs no upload
  const thumbRef = ref(storage, `${uploadRoot}/thumbs/${filename}.webp`);
  uploadBytes(thumbRef, thumbData);

  // metadata:
  const metadata = {
    customMetadata: {
      // originalName: file.name,
      hashId,
      // originalModified: `${file.lastModified}`,
      [owner.uid || ""]: "owner"
    }
  };

  // upload files
  // 'file' comes from the Blob or File API
  return uploadBytes(storageRef, zipData, metadata);

  // // no thumbs no upload
  // // upload thumbs
  // fetch(uploadContent.thumbData) // local fetch since thumbData is client
  //   .then((res) => res.blob())
  //   .then((blob) => {
  //     uploadBytes(thumbRef, blob).then((snapshot) => {
  //       console.log("Uploaded success: ", snapshot);
  //     });
  //   });
};

export const downloadExtract = async (path: string) => {
  const fileRef = ref(getStorage(), path);
  const downloadUrl = await getDownloadURL(fileRef);

  const blob = await fetch(downloadUrl).then((r) => r.blob());

  const zip = new JSZip();
  const content = await zip.loadAsync(blob);
  return content;
  // .then((content) => console.log("...zip content", content));
};

export const downloadFile = async (path: string) => {
  const fileRef = ref(getStorage(), path);
  const downloadUrl = await getDownloadURL(fileRef);

  // creating an invisible element
  // equivalent to <a href="path of file" download="file name">
  // "data:text/plain;charset=utf-8, " + encodeURIComponent(text)
  const element = document.createElement("a");
  element.setAttribute("href", downloadUrl);
  element.setAttribute("download", "Menu4K.apk");
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
