import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";

import {
  AppBar,
  createTheme,
  IconButton,
  Toolbar,
  Typography
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import SignIn from "./pages/SignIn/SignIn";
import Dashboard from "./pages/Dashboard/Dashboard";
import ReadPsd from "./pages/Test/TestReadPsd";
import PublishPsd from "./pages/Test/PublishPsd";
import { AppProvider } from "./provider/AppProvider";
import { AuthProvider } from "./provider/AuthProvider";
import { signOut } from "firebase/auth";
import { auth } from "./firebaseSetup";
import TestReadZip from "./pages/Test/TestReadZip";
import Config from "./components/Config/Config";
import { SettingsIcon } from "./icons";
import PhotoBoothPage from "./pages/PhotoBoothPage/PhotoBoothPage";
import MediaPipeEx from "./pages/Test/MediaPipeEx";
import { CookiesProvider } from "react-cookie";
// import TestTensorflow from "./pages/Test/TestTensorFlow";

const darkTheme = createTheme({
  palette: {
    mode: "light"
  }
});

const Logout = () => {
  const nav = useNavigate();
  useEffect(() => {
    if (auth.currentUser && !auth.currentUser?.isAnonymous) {
      console.debug("Signing out user");
      signOut(auth).then(() => window.setTimeout(nav, 1000, "/"));
    } else {
      console.debug("No need to logout anonymous user.");
    }
  }, []);
  return <Typography variant="body1">Logging out...</Typography>;
};

const WithButtons: React.FC<{
  children?: React.ReactElement;
}> = ({ children }) => {
  const nav = useNavigate();
  return children ? (
    children
  ) : (
    <>
      <Outlet />
      <div style={{ position: "fixed", top: "16px", right: "16px" }}>
        <IconButton onClick={() => nav("/config")}>
          <SettingsIcon />
        </IconButton>
      </div>
    </>
  );
};

const App = () => {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <AuthProvider>
        <AppProvider>
          <ThemeProvider theme={darkTheme}>
            <Router>
              <Routes>
                <Route path="/config" element={<Config />} />

                <Route element={<WithButtons />}>
                  <Route path="/" element={<Dashboard />} />
                </Route>

                <Route
                  path="ps42/2024/:templateName"
                  element={<PhotoBoothPage />}
                />
                <Route path="/testzip" element={<TestReadZip />} />
                <Route path="/savepsd" element={<PublishPsd />} />
                <Route path="/mediapipex" element={<MediaPipeEx />} />
                {/* <Route path="/tensor" element={<TestTensorflow />} /> */}
              </Routes>
            </Router>
          </ThemeProvider>
        </AppProvider>
      </AuthProvider>
    </CookiesProvider>
  );
};

export default App;
