import { ShootEvent } from "./ShootSession";

export default (
  event: ShootEvent | undefined,
  displayCanvas: HTMLCanvasElement | null // shutter of camera
) => {
  if (!event || !displayCanvas) return;

  const elapsed = event.elapsed!;
  const elapseEaseOut = -Math.pow(elapsed - 1, 2) + 1;
  if (event.type === "intro" || event.type === "final_intro") {
    displayCanvas.style.transform =
      event.type === "final_intro" ? "" : "scaleX(-1)";
    displayCanvas.style.opacity = `${elapsed}`;
    displayCanvas.style.opacity = "";
    displayCanvas.style.clipPath = `inset(${(1 - elapseEaseOut) * 100}% 0 0 0)`;
  } else if (event.type === "pose") {
    displayCanvas.style.opacity = "";
    displayCanvas.style.filter = "";
  } else if (event.type === "outro") {
    displayCanvas.style.clipPath = `inset(${elapseEaseOut * 100}% 0 0 0)`;
  } else if (event.type === "shutter_open") {
    displayCanvas.style.clipPath = `circle(${
      Math.max(1 - elapseEaseOut, 0.03) * 100
    }%)`;
  } else if (event.type === "shutter_close") {
    displayCanvas.style.filter = `contrast(0) brightness(100) `;
    displayCanvas.style.clipPath = `circle(${elapseEaseOut * 100}%)`;
  } else if (event.type === "process") {
    displayCanvas.style.filter = `contrast(${elapseEaseOut})`;
    displayCanvas.style.filter += ` brightness(${-4 * elapsed + 5})`;
  } else if (event.type === "preview") {
    displayCanvas.style.filter = "";
  }
};
