const REFRESH_INTERVAL = 20;

// reload, exposure, final_process should not have duration

type ShootEventType =
  | "reload"
  | "intro"
  | "pose"
  | "shutter_open"
  | "exposure" // save to film
  | "shutter_close"
  | "process"
  | "preview"
  | "outro"
  | "final_process"
  | "finished"
  | "final_intro"
  | "final_preview";

export type ShootEvent = {
  type: ShootEventType;
  snapIndex?: number;
  elapsed?: number; // # elpased percentage.
};

export class ShootSession {
  snapCount;
  snapIndex = 0;
  event: ShootEvent = { type: "finished" }; // current event
  consumedEvent: ShootEvent = { type: "finished" };
  startTime = -1;
  timerInterval = -1;
  lengthLookup: Record<ShootEventType, number>;
  finishCallback?: () => void;

  static poseLength = 3000;
  static shutterSpeed = 250;
  static processLength = 1000; // the animation that turns white to image
  static previewLength = 1000; // after shot, show the result
  static introLength = 250;
  static outroLength = 250;
  static finalIntroLength = 500;
  static finalPreviewLength = 2000;

  constructor(snapCount: number, finishCallback?: () => void) {
    this.snapCount = snapCount;
    this.lengthLookup = {
      intro: ShootSession.introLength,
      reload: 0,
      pose: ShootSession.poseLength,
      shutter_open: ShootSession.shutterSpeed,
      exposure: 0,
      shutter_close: ShootSession.shutterSpeed,
      process: ShootSession.processLength,
      preview: ShootSession.previewLength,
      outro: ShootSession.outroLength,
      final_process: 0,
      final_intro: ShootSession.finalIntroLength,
      final_preview: ShootSession.finalPreviewLength,
      finished: 0
    };

    this.finishCallback = finishCallback;
  }

  destructor() {
    console.log("...destructor called");
    window.clearInterval(this.timerInterval);
  }

  consumeEvent = (): ShootEvent | undefined => {
    if (
      this.consumedEvent.type !== this.event.type ||
      this.consumedEvent.elapsed !== this.event.elapsed
    ) {
      this.consumedEvent = { ...this.event, snapIndex: this.snapIndex };
      return this.consumedEvent;
    }
  };

  resume = () => {
    this.startTime = new Date().getTime();
    window.clearInterval(this.timerInterval);
    this.timerInterval = window.setInterval(this.run, REFRESH_INTERVAL);
  };

  restart = () => {
    this.snapIndex = 0;
    this.event = { type: "reload", elapsed: 0 };
    this.resume();
  };

  private run = () => {
    const { type: _state } = this.event;
    const consumedElapsed = this.consumedEvent.elapsed!;
    const consumedState = this.consumedEvent.type;

    const propagateState = (type: ShootEventType) => {
      const consumedEventLength = this.lengthLookup[consumedState];
      const e = (new Date().getTime() - this.startTime) / consumedEventLength;
      this.event.elapsed = Math.min(1, parseFloat(e.toFixed(2)));

      if (consumedElapsed >= 1 && this.event.type === consumedState) {
        this.startTime = new Date().getTime();
        this.event = { type, elapsed: this.lengthLookup[type] === 0 ? 1 : 0 };
        return true;
      }
      return false;
    };

    if (_state === "finished") {
      window.clearInterval(this.timerInterval);
      if (this.finishCallback) {
        this.finishCallback();
      }
    } else if (_state === "reload") {
      propagateState("intro");
    } else if (_state === "intro") {
      propagateState("pose");
    } else if (_state === "pose") {
      propagateState("shutter_open");
    } else if (_state === "shutter_open") {
      propagateState("exposure");
    } else if (_state === "exposure") {
      propagateState("shutter_close");
    } else if (_state === "shutter_close") {
      propagateState("process");
    } else if (_state === "process") {
      propagateState("preview");
    } else if (_state === "preview") {
      propagateState("outro");
    } else if (_state === "outro") {
      if (this.snapIndex >= this.snapCount - 1) {
        propagateState("final_process");
      } else {
        if (propagateState("reload")) {
          this.snapIndex += 1;
        }
      }
    } else if (_state === "final_process") {
      propagateState("final_intro");
    } else if (_state === "final_intro") {
      propagateState("final_preview");
    } else if (_state === "final_preview") {
      propagateState("finished");
    }
  };
}
