import { Res, VideoFrameMetadata } from "../../entities";
import React, { useEffect, useRef, useState } from "react";
import { Camera } from "@mediapipe/camera_utils";

const useCaptureNew = (
  record: boolean,
  videoRef: React.MutableRefObject<HTMLVideoElement | null>,
  resHint: Res = { width: 1280, height: 720 },
  callback?: (frame: HTMLVideoElement, metadata: Res) => void,
  // realRes?: React.MutableRefObject<Res | null>,
  realResUpdateCallback?: (res: Res) => void,
  direction: "front" | "back" = "front"
) => {
  const realRes = useRef<Res | null>(null);
  const camera = useRef<Camera | null>(null);

  useEffect(() => {
    const video = videoRef.current!;
    camera.current = new Camera(video, {
      onFrame: async () => {
        if (
          realRes.current === null ||
          realRes.current.height !== video.videoHeight ||
          realRes.current.width !== video.videoWidth
        ) {
          realRes.current = {
            width: video.videoWidth,
            height: video.videoHeight
          };
          if (realResUpdateCallback) {
            realResUpdateCallback(realRes.current);
          }
        }

        if (callback && realRes.current) {
          callback(video, realRes.current);
        }
        // if (inputVideoRef.current) {
        //   await selfieSegmentation.send({ image: inputVideoRef.current });
        //   console.log(
        //     "...inputVideoRef.current",
        //     inputVideoRef.current.videoHeight,
        //     inputVideoRef.current.videoWidth
        //   );
        // }
      },
      width: resHint.width,
      height: resHint.height
    });

    return () => {
      camera.current?.stop();
    };
  }, []);

  useEffect(() => {
    if (record) {
      camera.current?.start();
    } else {
      camera.current?.stop();
    }
  }, [record]);
};

const useCaptureOri = (
  record: boolean,
  videoRef: React.MutableRefObject<HTMLVideoElement>,
  resHint: Res = { width: 1280, height: 720 },
  callback?: (frame: HTMLVideoElement, metadata: Res) => void,
  // realRes?: React.MutableRefObject<Res | null>,
  realResUpdateCallback?: (res: Res) => void,
  direction: "front" | "back" = "front"
) => {
  const realRes = useRef<Res | null>(null);
  const vStream = useRef<MediaStream | null>(null);
  // iterate through all the frame processors.
  const processFrameCallback = (
    now: DOMHighResTimeStamp,
    metadata: VideoFrameMetadata
  ) => {
    if (
      realRes.current === null ||
      realRes.current.height !== videoRef.current.videoHeight ||
      realRes.current.width !== videoRef.current.videoWidth
    ) {
      if (realResUpdateCallback) {
        realRes.current = {
          width: videoRef.current.videoWidth,
          height: videoRef.current.videoHeight
        };
        realResUpdateCallback(realRes.current);
      }
    }

    if (callback) callback(videoRef.current, realRes.current!);
    if (videoRef.current) {
      videoRef.current.requestVideoFrameCallback(processFrameCallback);
    }
  };

  const terminateStream = () => {
    if (vStream.current) {
      vStream.current.getTracks().forEach((track) => track.stop());
      vStream.current = null;
    }
  };

  useEffect(() => {
    return terminateStream;
  }, []);

  useEffect(() => {
    if (record) {
      console.log("....resHint:", resHint);
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: "user",
            width: { ideal: resHint.width },
            height: { ideal: resHint.height }
          }
        })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          videoRef.current.requestVideoFrameCallback(processFrameCallback);
          vStream.current = stream;
        });
    } else {
      terminateStream();
    }
  }, [record]);
};

// callback for the pix color of the mouse click
export const getPixColor = (
  e: React.MouseEvent<HTMLCanvasElement>,
  img: HTMLVideoElement | HTMLImageElement | HTMLCanvasElement
) => {
  const ColorPickCanvas = document.createElement("canvas");
  ColorPickCanvas.width = 1;
  ColorPickCanvas.height = 1;

  const rect = e.currentTarget.getBoundingClientRect();
  const scale = e.currentTarget.height / rect.height;

  const x = (e.clientX - rect.left) * scale;
  const y = (e.clientY - rect.top) * scale;

  console.debug("Pick color from: ", { x, y });

  const ctx = ColorPickCanvas.getContext("2d");
  ctx?.drawImage(img, x, y, 1, 1, 0, 0, 1, 1);
  const p = ctx?.getImageData(0, 0, 1, 1).data;

  ColorPickCanvas.remove();
  return p;
};

export default useCaptureNew;
