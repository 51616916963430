import { Res } from "../entities";

const getMaskBounds = (snapRes: Res, captureRes: Res) => {
  const snapAspect = snapRes.width / snapRes.height;
  const captureAspect = captureRes.width / captureRes.height;
  let result;
  if (captureAspect > snapAspect) {
    result = {
      // scale: captureRes.height / snapRes.height,
      res: { height: captureRes.height, width: captureRes.height * snapAspect },
      top: 0,
      left: (captureRes.width - captureRes.height * snapAspect) / 2
    };
  } else {
    result = {
      // scale: captureRes.width / snapRes.width,
      res: { height: captureRes.width / snapAspect, width: captureRes.width },
      top: (captureRes.height - captureRes.width / snapAspect) / 2,
      left: 0
    };
  }
  return result;
};

export const maskPhoto = (
  source: ImageBitmap | HTMLCanvasElement | HTMLVideoElement,
  mask: ImageBitmap | HTMLCanvasElement,
  canvasBuffer: HTMLCanvasElement
) => {
  const sourceRes =
    source.constructor.name === "HTMLVideoElement"
      ? {
          width: (source as HTMLVideoElement).videoWidth,
          height: (source as HTMLVideoElement).videoHeight
        }
      : {
          width: source.width,
          height: source.height
        };

  const { res: destRes, ...destInfo } = getMaskBounds(
    {
      width: mask.width,
      height: mask.height
    },
    sourceRes
  );

  canvasBuffer.width = mask.width;
  canvasBuffer.height = mask.height;

  const ctx = canvasBuffer.getContext("2d");

  if (ctx) {
    ctx.clearRect(0, 0, mask.width, mask.height);
    ctx.globalCompositeOperation = "source-over";
    ctx.drawImage(mask, 0, 0);
    ctx.globalCompositeOperation = "source-in";
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      source,
      destInfo.left,
      destInfo.top,
      destRes.width,
      destRes.height,
      0,
      0,
      mask.width,
      mask.height
    );
  }
  return canvasBuffer;
};

export const copyCanvas = (
  source: HTMLCanvasElement | HTMLVideoElement,
  dest: HTMLCanvasElement | null
) => {
  if (dest) {
    let { width, height } = source;
    if ((source as HTMLVideoElement).videoWidth) {
      width = (source as HTMLVideoElement).videoWidth;
      height = (source as HTMLVideoElement).videoHeight;
    }
    dest.width = width;
    dest.height = height;

    const ctx = dest.getContext("2d");
    ctx?.drawImage(source, 0, 0, width, height);
  }
};
