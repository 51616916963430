import React, { useEffect, useRef } from "react";

const vShader = `
  attribute vec2 c; void main(void) { gl_Position=vec4(c, 0.0, 1.0); }
`;

const fShader = `
  precision mediump float;
    
  uniform sampler2D tex;
  uniform float texWidth;
  uniform float texHeight;

  uniform vec3 keyColor;
  uniform float similarity;
  uniform float smoothness;
  uniform float spill;

  // From https://github.com/libretro/glsl-shaders/blob/master/nnedi3/shaders/rgb-to-yuv.glsl
  vec2 RGBtoUV(vec3 rgb) {
    return vec2(
      rgb.r * -0.169 + rgb.g * -0.331 + rgb.b *  0.5    + 0.5,
      rgb.r *  0.5   + rgb.g * -0.419 + rgb.b * -0.081  + 0.5
    );
  }

  vec4 ProcessChromaKey(vec2 texCoord) {
    vec4 rgba = texture2D(tex, texCoord);
    float chromaDist = distance(RGBtoUV(texture2D(tex, texCoord).rgb), RGBtoUV(keyColor));

    float baseMask = chromaDist - similarity;
    float fullMask = pow(clamp(baseMask / smoothness, 0., 1.), 1.5);
    rgba.a = fullMask;

    float spillVal = pow(clamp(baseMask / spill, 0., 1.), 1.5);
    float desat = clamp(rgba.r * 0.2126 + rgba.g * 0.7152 + rgba.b * 0.0722, 0., 1.);
    rgba.rgb = mix(vec3(desat, desat, desat), rgba.rgb, spillVal);

    return rgba;
  }

  void main(void) {
    vec2 texCoord = vec2(gl_FragCoord.x/texWidth, 1.0 - (gl_FragCoord.y/texHeight));
    gl_FragColor = ProcessChromaKey(texCoord);
  }
`;

const fNoShader = `
  precision mediump float;
    
  uniform sampler2D tex;
  uniform float texWidth;
  uniform float texHeight;

  vec4 ProcessChromaKey(vec2 texCoord) {
    vec4 rgba = texture2D(tex, texCoord);
    return rgba;
  }

  void main(void) {
    vec2 texCoord = vec2(gl_FragCoord.x/texWidth, 1.0 - (gl_FragCoord.y/texHeight));
    gl_FragColor = ProcessChromaKey(texCoord);
  }
`;

export interface FrameProcessor {
  (
    // now: DOMHighResTimeStamp,
    res: { width: number; height: number },
    imageSource: TexImageSource
  ): HTMLCanvasElement | null;
}
export interface ChromaKeySpec {
  color: number[];
  similar: number;
  smooth: number;
  spill: number;
}

/**
 * Given the chroma spec, and a canvas, it will return a callback.
 * The callback will apply the chroma key on the given canvas
 * @param canvasRef // the process buffer.
 * @param chromaKey
 * @returns
 */
export const useChromaKeyProcessor = (
  chromaKey?: ChromaKeySpec,
  disabled?: boolean
): React.MutableRefObject<FrameProcessor> => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const glRef = useRef<WebGLRenderingContext>();
  const glProgRef = useRef<WebGLProgram>();
  const FrameProcessorRef = useRef<FrameProcessor>(
    (): HTMLCanvasElement | null => {
      return null;
    }
  );

  useEffect(() => {
    canvasRef.current = document.createElement("canvas");
    return () => canvasRef.current?.remove();
  }, []);

  useEffect(() => {
    if (canvasRef.current && disabled) {
      canvasRef.current.height = 1;
      canvasRef.current.height = 1;
    }
  }, [disabled]);

  const reset = (disabled: boolean) => {
    const gl = canvasRef.current?.getContext("webgl", {
      premultipliedAlpha: false
    });
    if (!gl) return;

    const vs: WebGLShader = gl.createShader(gl.VERTEX_SHADER)!;
    gl.shaderSource(vs, vShader);
    gl.compileShader(vs);

    const fs: WebGLShader = gl.createShader(gl.FRAGMENT_SHADER)!;
    gl.shaderSource(fs, chromaKey ? fShader : fNoShader);
    gl.compileShader(fs);
    if (!gl.getShaderParameter(fs, gl.COMPILE_STATUS)) {
      console.error(gl.getShaderInfoLog(fs));
    }

    const prog = gl.createProgram()!;
    gl.attachShader(prog, vs);
    gl.attachShader(prog, fs);
    gl.linkProgram(prog);
    gl.useProgram(prog);

    const vb = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vb);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array([-1, 1, -1, -1, 1, -1, 1, 1]),
      gl.STATIC_DRAW
    );

    const coordLoc = gl.getAttribLocation(prog, "c");
    gl.vertexAttribPointer(coordLoc, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(coordLoc);

    gl.activeTexture(gl.TEXTURE0);
    const tex = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, tex);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

    glRef.current = gl;
    glProgRef.current = prog;
  };

  useEffect(() => {
    reset(disabled || false);

    const gl = glRef.current;
    const prog = glProgRef.current;

    if (!gl || !prog) {
      return;
    }

    const texLoc = gl.getUniformLocation(prog, "tex");
    const texWidthLoc = gl.getUniformLocation(prog, "texWidth");
    const texHeightLoc = gl.getUniformLocation(prog, "texHeight");
    const keyColorLoc = gl.getUniformLocation(prog, "keyColor");
    const similarityLoc = gl.getUniformLocation(prog, "similarity");
    const smoothnessLoc = gl.getUniformLocation(prog, "smoothness");
    const spillLoc = gl.getUniformLocation(prog, "spill");

    FrameProcessorRef.current = (res, imgSource): HTMLCanvasElement | null => {
      if (canvasRef.current === null || res === null) {
        return null;
      }

      if (
        canvasRef.current.width !== imgSource.width ||
        canvasRef.current.height !== res.height
      ) {
        canvasRef.current.width = res.width;
        canvasRef.current.height = res.height;
      }
      gl.viewport(0, 0, res.width, res.height);
      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGB,
        gl.RGB,
        gl.UNSIGNED_BYTE,
        imgSource
      );

      gl.uniform1i(texLoc, 0);
      gl.uniform1f(texWidthLoc, res.width);
      gl.uniform1f(texHeightLoc, res.height);

      if (chromaKey !== undefined && !disabled) {
        gl.uniform3f(
          keyColorLoc,
          chromaKey.color[0] / 255,
          chromaKey.color[1] / 255,
          chromaKey.color[2] / 255
        );
        gl.uniform1f(similarityLoc, chromaKey.similar);
        gl.uniform1f(smoothnessLoc, chromaKey.smooth);
        gl.uniform1f(spillLoc, chromaKey.spill);
      }
      gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
      return canvasRef.current;
    };
  }, [chromaKey, disabled]);

  return FrameProcessorRef;
};
